<template>
    <v-container fluid>
        <v-card elevation="4" outlined :loading="loading">
            <v-card-title class="pb-2 pt-2">
                <v-icon left color="red">mdi-car-emergency</v-icon>
                Streifenmanagement
            </v-card-title>

            <div v-for="unitType in unitTypeKeys" :key="unitType">
                <div class="divider-with-text">
                    {{ accountTypeToLabel(unitType) }}
                </div>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" xl="4" lg="6" md="12" sm="12" v-for="(unit, k) in patrolUnits.filter(n => n.unitType === unitType).sort((a, b) => a.name?.localeCompare(b.name))" :key="k">
                            <v-card outlined elevation="2" tile min-height="110px">
                                <v-card-text>
                                    <div v-if="unit.employee"><b>Beamter 1: </b>{{ unit.employee.displayName }}<br/></div>
                                    <div v-for="(partner, k) in unit.partners" :key="k"><b>Beamter {{ k + 2 }}: </b>{{
                                            partner.displayName
                                        }}<br/></div>
                                    <div v-if="unit.partners.length === 0"><b>Beamter 2: </b><i>Niemand</i><br/></div>

                                    <div v-if="unit.district"><b>Einteilung: </b>{{ unit.district }}<br/></div>
                                    <div v-else><b>Einteilung: </b><i>Keine genaue Einteilung</i><br/></div>
                                </v-card-text>

                                <v-btn style="position: absolute; top: 8px; right: 8px"
                                       :color="getStatusCode(unit.status).color" x-small
                                       v-if="unit.status" dark outlined>
                                    <v-icon small class="pr-2">{{ getStatusCode(unit.status).icon }}</v-icon>
                                    {{ getStatusCode(unit.status).label }}
                                </v-btn>

                                <v-btn style="position: absolute; top: 32px; right: 8px" color="primary" x-small
                                       v-if="unit.radioChannel">
                                    <v-icon small left>mdi-radio-handheld</v-icon>
                                    {{ getRadioFrequencyLabel(unit.radioChannel) }}
                                </v-btn>

                                <v-btn style="position: absolute; top: 56px; right: 8px" color="blue-grey" x-small
                                       v-if="unit.vehicle" dark>
                                    <v-icon small left>mdi-car</v-icon>
                                    {{ unit.vehicle }}
                                </v-btn>

                                <div
                                    style="position: absolute; top: -12px; left: -1px;font-family: Arial, sans-serif;font-weight: bolder;">
                                    <v-btn color="yellow" x-small elevation="4" tile class="ml-2" v-if="unit.controlCenter"
                                           light>
                                        <v-icon left small>mdi-phone</v-icon>
                                        Leitstelle
                                    </v-btn>

                                    <v-btn color="blue-grey" dark x-small elevation="1" class="ml-2"
                                           v-if="unit.name">{{ unit.name }}
                                    </v-btn>

                                    <v-btn color="info darken-4" x-small elevation="4" tile class="ml-2"
                                           v-if="unit.unitType === 'ZOLL'">ZOLL
                                    </v-btn>
                                    <v-btn color="info darken-1" x-small elevation="4" tile class="ml-2"
                                           v-if="unit.unitType === 'POLICE'">POLIZEI
                                    </v-btn>
                                    <v-btn color="info darken-1" x-small elevation="4" tile class="ml-2"
                                           v-if="unit.unitType === 'CORRECTIONAL'">Justiz
                                    </v-btn>
                                    <v-btn color="orange" dark x-small elevation="4" tile class="ml-2"
                                           v-if="unit.unitType === 'FIREFIGHTER'">
                                        Feuerwehr
                                    </v-btn>

                                    <v-btn color="red" dark x-small elevation="4" tile class="ml-2"
                                           v-if="unit.unitType === 'MEDIC' && unit.accountType !== 'POLICE'">
                                        <v-icon left class="mr-1">mdi-hospital</v-icon>
                                        Rettungsdienst
                                    </v-btn>
                                    <v-btn color="info darken-1" dark x-small elevation="4" tile class="ml-2"
                                           v-if="unit.unitType === 'MEDIC' && unit.accountType === 'POLICE'">
                                        <v-icon left class="mr-1">mdi-police-badge-outline</v-icon>
                                        PMD
                                    </v-btn>

                                    <v-btn color="blue-grey" dark x-small elevation="4" tile class="ml-2"
                                           v-if="unit.unitType === 'GOVERNMENT'">Regierung
                                    </v-btn>
                                    <v-btn color="pink" dark x-small elevation="4" tile class="ml-2"
                                           v-if="unit.unitType === 'ADMIN'">
                                        <v-icon left small>mdi-shield-outline</v-icon>
                                        Admin
                                    </v-btn>

                                    <v-btn color="error darken-1" dark x-small elevation="4" class="ml-2"
                                           v-if="isMemberOfPatrolUnit(unit)">Du
                                    </v-btn>
                                </div>

                                <div style="position: absolute; bottom: -10px; right: -10px">
                                    <v-btn x-small color="error" class="ml-2" fab elevation="8" @click="deleteUnit(unit)"
                                           v-if="canEditPatrolUnit(unit) || user.orgAdmin || user.admin">
                                        <v-icon>mdi-delete-outline</v-icon>
                                    </v-btn>
                                    <v-btn x-small color="success" class="ml-2" fab elevation="8"
                                           @click="toggleControlCenterForUnit(unit)"
                                           v-if="(!isAnyPatrolUnitControlCenter && !unit.controlCenter) && (canEditPatrolUnit(unit) || user.orgAdmin || user.admin)">
                                        <v-icon>mdi-phone-outline</v-icon>
                                    </v-btn>
                                    <v-btn x-small color="error" class="ml-2" fab elevation="8"
                                           @click="toggleControlCenterForUnit(unit)"
                                           v-if="unit.controlCenter && (canEditPatrolUnit(unit) || user.orgAdmin || user.admin)">
                                        <v-icon>mdi-phone-off-outline</v-icon>
                                    </v-btn>
                                    <v-btn x-small color="warning" class="ml-2" fab elevation="8" @click="editUnit(unit)"
                                           v-if="canEditPatrolUnit(unit) || user.orgAdmin || user.admin">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </div>

            <v-divider v-if="!isMemberOfAnyPatrolUnit()"/>
            <v-card-actions v-if="!isMemberOfAnyPatrolUnit()" class="pb-0">
                <v-spacer/>
                <v-btn small tile elevation="0" dark color="green" @click="createUnit()">
                    <v-icon left>mdi-plus-outline</v-icon>
                    Neue Streife anlegen
                </v-btn>
            </v-card-actions>
            <v-divider class="mt-3"/>
            <v-card-title class="pt-2 pb-2">
                <v-icon left color="red">mdi-map</v-icon>
                Legende
                <v-spacer/>
                <v-btn fab elevation="0" x-small v-if="showLegend" @click="showLegend = !showLegend" color="primary">
                    <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
                <v-btn fab elevation="0" x-small v-if="!showLegend" @click="showLegend = !showLegend" color="primary">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider v-if="showLegend"/>
            <v-row v-if="showLegend">
                <v-col>
                    <v-simple-table dense>
                        <thead>
                        <tr>
                            <th>Status</th>
                            <th>Beschreibung</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, k) in filteredStatusCodesLeft" :key="k">
                            <td>
                                <v-btn :color="item.color" x-small dark @click="updateStatusOfUnit(myUnit, k)">
                                    <v-icon small left>{{ item.icon }}</v-icon>
                                    {{ item.label }}
                                </v-btn>
                            </td>
                            <td>{{ item.description }}</td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
                <v-col>
                    <v-simple-table dense>
                        <thead>
                        <tr>
                            <th>Status</th>
                            <th>Beschreibung</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, k) in filteredStatusCodesRight" :key="k">
                            <td>
                                <v-btn :color="item.color" x-small dark @click="updateStatusOfUnit(myUnit, k)">
                                    <v-icon small left>{{ item.icon }}</v-icon>
                                    {{ item.label }}
                                </v-btn>
                            </td>
                            <td>{{ item.description }}</td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card>


        <!-- Create Record Entry Dialog -->
        <v-dialog v-model="createPatrolUnitDialog" width="600" persistent>
            <v-card outlined>
                <v-card-title class="pa-3" v-if="createPatrolUnitMode === 'CREATE'">
                    <v-icon left>mdi-car-emergency</v-icon>
                    Streife Anlegen
                </v-card-title>
                <v-card-title class="pa-3" v-if="createPatrolUnitMode === 'EDIT'">
                    <v-icon left>mdi-car-emergency</v-icon>
                    Streife Bearbeiten
                </v-card-title>
                <v-divider/>
                <v-card-text class="pl-3 pt-2 pr-3">

                    <v-text-field v-model="createPatrolUnitEntity.name"
                                  dense solo outlined prepend-inner-icon="mdi-bullhorn-outline" color="primary"
                                  placeholder="Rufname"/>

                    <v-autocomplete multiple v-model="createPatrolUnitEntity.partners" clearable
                                    prepend-inner-icon="mdi-account-group" placeholder="Partner" dense solo outlined
                                    :items="employees"
                                    item-text="displayName"
                                    return-object/>

                    <v-text-field clearable v-model="createPatrolUnitEntity.district"
                                  dense solo outlined prepend-inner-icon="mdi-map" placeholder="Einteilung / Ort"/>

                    <v-text-field type="number" v-model="createPatrolUnitEntity.radioChannel" clearable
                                  dense solo outlined prepend-inner-icon="mdi-radio-handheld"
                                  placeholder="Funkfrequenz"/>

                    <v-text-field v-model="createPatrolUnitEntity.vehicle" clearable
                                  v-if="!['MEDIC'].includes(userAccountType)"
                                  dense solo outlined prepend-inner-icon="mdi-car" placeholder="Kennzeichen"/>

                    <v-select v-model="createPatrolUnitEntity.status" prepend-inner-icon="mdi-steering"
                              dense solo outlined placeholder="Status" :items="filteredStatusCodes" item-text="label"
                              item-value="key"/>

                    <v-select v-model="createPatrolUnitEntity.unitType" :items="myUnitTypes"
                              v-if="myUnitTypes.length > 1"
                              prepend-inner-icon="mdi-text-box-outline"
                              dense solo outlined placeholder="Status"/>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-btn small color="success" @click="createPatrolUnit()">
                        <v-icon left>mdi-content-save</v-icon>
                        Anlegen
                    </v-btn>

                    <v-btn small color="grey" @click="fetchEmployeesDifferentUnit('MEDIC')"
                           v-if="user.accountType !== 'MEDIC' && !isAnyEmployeeLoadedOfAccountType('MEDIC')">
                        <v-icon class="pr-1" small>mdi-refresh</v-icon>
                        👩‍⚕️
                    </v-btn>
                    <v-btn small color="grey" @click="fetchEmployeesDifferentUnit('POLICE')"
                           v-if="user.accountType !== 'POLICE' && !isAnyEmployeeLoadedOfAccountType('POLICE')">
                        <v-icon class="pr-1" small>mdi-refresh</v-icon>
                        👮🏼‍♂️
                    </v-btn>
                    <v-btn small color="grey" @click="fetchEmployeesDifferentUnit('ZOLL')"
                           v-if="user.accountType !== 'ZOLL' && !isAnyEmployeeLoadedOfAccountType('ZOLL')">
                        <v-icon class="pr-1" small>mdi-refresh</v-icon>
                        🚚
                    </v-btn>
                    <v-spacer/>
                    <v-btn small color="error" @click="createPatrolUnitDialog = false;">
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<style>
.divider-with-text {
    font-size: 16px;
    font-family: 'Nunito', 'Arial', serif;
    display: flex;
    align-items: center;
}

.divider-with-text::before {
    flex: 1;
    content: '';
    padding: 1px;
    background-color: #3a3a3a;
    margin-right: 10px;
}

.divider-with-text::after {
    flex: 1;
    content: '';
    padding: 1px;
    background-color: #3a3a3a;
    margin-left: 10px;
}
</style>

<script>
import axios                          from "axios";
import yaml                           from "js-yaml";
import {ACCOUNT_TYPES, ORGANIZATIONS} from "@/config";

export default {
    data:     () => ({
        createPatrolUnitDialog: false,
        createPatrolUnitMode:   'CREATE',
        createPatrolUnitEntity: {
            id: null,

            district:     null,
            radioChannel: null,
            vehicle:      null,
            status:       null,

            partners: [],
            employee: null,

            name: null,

            unitType:    null,
            accountType: null,

            controlCenter: false,
        },

        unitTypes: {
            'POLICE': ['POLICE', 'ZOLL', 'MEDIC', 'CORRECTIONAL'],
            'ZOLL':   ['POLICE', 'ZOLL', 'MEDIC', 'CORRECTIONAL'],
            'MEDIC':  ['MEDIC', 'FIREFIGHTER'],
        },

        statusCodes: {},

        employees:   [],
        patrolUnits: [],
        loading:     false,

        showLegend: true,
    }),
    computed: {
        myUnitTypes() {
            return this.unitTypes[this.user.accountType] || [this.user.accountType];
        },
        unitTypeKeys() {
            let keys = [];

            let availableKeys = [];

            for (let unit of this.patrolUnits) {
                availableKeys[unit.unitType] = true;
            }

            if (availableKeys[this.userAccountType]) {
                keys.push(this.userAccountType);
            }

            for (let foreignAccountType in availableKeys) {
                if (foreignAccountType !== this.userAccountType) {
                    keys.push(foreignAccountType);
                }
            }

            return keys;
        },
        filteredStatusCodesLeft() {
            let codes = this.statusCodes;
            let data  = {}

            Object.keys(codes).forEach(k => {
                if ((codes[k].accountTypes === undefined || codes[k].accountTypes.includes(this.user.accountType)) && k.startsWith('CODE_')) {
                    data[k] = codes[k];
                }
            })

            return data;
        },
        filteredStatusCodesRight() {
            let codes = this.statusCodes;
            let data  = {}

            Object.keys(codes).forEach(k => {
                if ((codes[k].accountTypes === undefined || codes[k].accountTypes.includes(this.user.accountType)) && !k.startsWith('CODE_')) {
                    data[k] = codes[k];
                }
            })

            return data;
        },
        filteredStatusCodes() {
            let codes = {...this.filteredStatusCodesLeft, ...this.filteredStatusCodesRight}

            return Object.keys(codes).map(key => {
                return {key: key, label: codes[key].label, icon: codes[key].icon}
            })
        },
        isAnyPatrolUnitControlCenter() {
            let any = false;

            let myTypes = [this.userAccountType];

            if (ORGANIZATIONS.EXECUTIVE.includes(this.userAccountType)) {
                myTypes = ORGANIZATIONS.EXECUTIVE;
            } else if (ORGANIZATIONS.MEDIC.includes(this.userAccountType)) {
                myTypes = ORGANIZATIONS.MEDIC;
            }

            this.patrolUnits.forEach(pu => {
                if (pu.controlCenter && myTypes.includes(pu.unitType)) any = true;
            })
            return any;
        },
        myUnit() {
            let unit = null;
            this.patrolUnits.forEach(pu => {
                if (unit != null) return;
                if (this.isMemberOfPatrolUnit(pu)) unit = pu;
            })
            return unit;
        }
    },
    methods:  {
        accountTypeToLabel(accountType) {
            for (let obj of ACCOUNT_TYPES) {
                if (obj.accountType === accountType) return obj.label;
            }
            return accountType;
        },
        getStatusCode(codeName) {
            return this.statusCodes[codeName] || this.statusCodes['CODE_1'];
        },
        getRadioFrequencyLabel(radioFreq) {
            radioFreq = parseInt(radioFreq || '0');
            if (radioFreq === 1) return 'Polizei';
            if (radioFreq === 10) return 'DRK';
            if (radioFreq === 79) return 'ADAC';
            if (radioFreq === 99) return 'Regierung';

            return radioFreq + ' MHz'
        },

        isEmployeeLoaded(employeeId) {
            for (let emp of this.employees) {
                if (emp.id === employeeId) return true;
            }
            return false;
        },

        isAnyEmployeeLoadedOfAccountType(accountType) {
            for (let emp of this.employees) {
                if (emp.accountType === accountType) return true;
            }
            return false;
        },

        canEditPatrolUnit(unit) {
            let myUnit = this.myUnit;

            if (myUnit) {
                return myUnit.id === unit.id || myUnit.controlCenter
            }

            return false;
        },

        isMemberOfAnyPatrolUnit() {
            let isMemberOfAny = false;

            this.patrolUnits.forEach(pu => {
                if (isMemberOfAny) return;
                if (this.isMemberOfPatrolUnit(pu)) isMemberOfAny = true;
            })

            return isMemberOfAny;
        },

        isMemberOfPatrolUnit(unit) {
            if (unit.employee.id === this.user.id) return true;
            let isMember = false;

            unit.partners?.forEach(partner => {
                if (partner.id === this.user.id) {
                    isMember = true;
                }
            });

            return isMember;
        },

        fetchPatrolUnits() {
            this.loading = true;
            return this.apiGetAllPatrolUnits().then(r => {
                let data = []

                r.data.forEach(pu => {
                    if (this.isMemberOfPatrolUnit(pu)) data.push(pu);
                });
                r.data.forEach(pu => {
                    if (!this.isMemberOfPatrolUnit(pu)) data.push(pu);
                });

                this.patrolUnits = data;
                this.loading     = false;
            })
        },

        deleteUnit(unit) {
            this.apiDeletePatrolUnit(unit.id).finally(() => this.fetchPatrolUnits());
        },
        createUnit() {
            this.createPatrolUnitEntity = {
                id: null,

                district:     null,
                radioChannel: null,
                vehicle:      null,
                status:       null,

                partners: [],
                employee: null,

                name: 'Streife ' + (this.patrolUnits.length + 1),

                unitType:    this.user.accountType,
                accountType: this.user.accountType,

                controlCenter: false,
            };
            this.createPatrolUnitDialog = true;
            this.createPatrolUnitMode   = 'CREATE';
        },

        fetchIntervalFunc() {
            if (window.fetchIntervalPU) return;
            window.fetchIntervalPU = true;

            this.fetchPatrolUnits().finally(() => {
                setTimeout(() => {
                    window.fetchIntervalPU = false;

                    if (this.$route.name === 'PatrolUnits')
                        this.fetchIntervalFunc();

                    console.log('Added new Request to Queue ...')
                }, 2000)
            });
        },

        fetchEmployeeList() {
            this.apiGetEmployees(false).then(r => {
                this.employees = r.data;

                this.employees.sort((a, b) => a.displayName < b.displayName ? -1 : a.displayName > b.displayName ? 1 : 0);
            })
        },

        fetchEmployeesDifferentUnit(accountType) {
            this.apiGetEmployeesByAccountType(false, accountType).then(r => {
                r.data.forEach((employee) => {
                    if (!this.isEmployeeLoaded(employee.id))
                        this.employees.push(employee);
                })
            })
        },

        fetchStatusCodeConfig() {
            axios.get('/patrol-unit-codes.yaml').then(r => {
                let data = r.data;
                try {
                    let codes        = yaml.load(data);
                    this.statusCodes = codes.items;
                } catch (e) {
                    console.log('failed to load patrol-unit-codes', e)
                }
                console.log(r.data);
            })
        },

        editUnit(unit) {
            this.createPatrolUnitDialog = true;
            this.createPatrolUnitMode   = 'EDIT';
            this.createPatrolUnitEntity = unit;
        },

        updateStatusOfUnit(unit, statusCode) {
            if (unit != null) {
                unit.status = statusCode;

                this.apiUpdatePatrolUnit(unit.id, unit);
            }
        },

        toggleControlCenterForUnit(unit) {
            unit.controlCenter = !unit.controlCenter;

            this.apiUpdatePatrolUnit(unit.id, unit);
        },

        createPatrolUnit() {
            if (this.createPatrolUnitEntity.id) {
                this.apiUpdatePatrolUnit(this.createPatrolUnitEntity.id, this.createPatrolUnitEntity).finally(() => {
                    this.fetchPatrolUnits();
                })
            } else {
                this.apiCreatePatrolUnit(this.createPatrolUnitEntity).finally(() => {
                    this.fetchPatrolUnits();
                })
            }
        }
    },
    mounted() {
        this.fetchPatrolUnits();
        this.fetchIntervalFunc();
        this.fetchEmployeeList();

        this.fetchStatusCodeConfig();
    }
}
</script>
